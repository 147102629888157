import styled from "styled-components";

const SessionMembernameContainer = styled.div`
  width: auto;
  display: flex;
  text-align: start;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
`;

export default SessionMembernameContainer;

