import styled from 'styled-components';

const MemberNameContainer = styled.div`
    width: auto;
    display: flex;
    text-align: start;
    color: #FFF;
    font-size: 23px;
    line-height: 30px;
`;

export default MemberNameContainer;